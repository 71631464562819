import { useState, useEffect } from "react";
import RequestTable from "./RequestTable";

const ViewRequests = () => {
  const [loadedRequests, setLoadedRequests] = useState([]);

  useEffect(() => {
    requests()
      .then((data) => {
        setLoadedRequests(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const requests = async () => {
    const response = await fetch(
      "https://www.innovations.co.ke/portalapi/public/api/viewAllTickets"
    );
    if (response.status !== 200) {
      throw new Error("There was an error viewing tickets :" + response.status);
    }
    const data = await response.json();
    return data;
  };

  return <RequestTable loadedRequests={loadedRequests} />;
};

export default ViewRequests;
