import { Route, Switch } from "react-router-dom";
import MainNavigation from "./MainNavigation";
import ViewRequests from "../../pages/ViewRequests";
import RequestDetails from "../../pages/RequestDetails";
import TicketSummary from "../../pages/TicketSummary";
import CommentList from "../../pages/CommentList";
import Reports from "../../pages/Reports";
import PassChange from "../../pages/PassChange";


const PortalBody = () => {
  return (
    <div>
      <MainNavigation />
      <div id="content">
        <Switch>
          <Route path="/" exact>
            <ViewRequests />
          </Route>
          <Route path="/ticket-details">
            <RequestDetails />
          </Route>
          <Route path="/ticket-summary">
            <TicketSummary />
          </Route>
          <Route path="/comment-list">
            <CommentList />
          </Route>
          <Route path="/reports">
            <Reports />
          </Route>
          <Route path="/pass-change">
            <PassChange />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default PortalBody;
