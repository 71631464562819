import ReportData from "./ReportData";
import { useState, useEffect } from "react";

const Reports = () => {
  const [loadedRequests, setLoadedRequests] = useState([]);

  useEffect(() => {
    requests()
      .then((data) => {
        setLoadedRequests(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const requests = async () => {
    const response = await fetch("https://www.innovations.co.ke/portalapi/public/api/openTickets");
    if (response.status !== 200) {
      throw new Error("There was an error fetching reports" + response.status);
    }
    const data = await response.json();
    return data;
  };

  return (
    <div className="row" style={{ marginTop: "5%" }}>
      <div className="col-md-6" style={{ marginBottom: "5%" }}>
        <ReportData
          head="pending Tickets"
          data={loadedRequests}
          color="#f0ad4e"
          dataKey="pending"
        />
      </div>
      <div className="col-md-6">
        <ReportData
          head="Open Tickets"
          data={loadedRequests}
          color="#0275d8"
          dataKey="open"
        />
      </div>

      <div className="col-md-6">
        <ReportData
          head="Unresolved Tickets"
          data={loadedRequests}
          color="#d9534f"
          dataKey="unresolved"
        />
      </div>
      <div className="col-md-6">
        <ReportData
          head="Closed Tickets"
          data={loadedRequests}
          color="#5cb85c"
          dataKey="closed"
        />
      </div>
    </div>
  );
};

export default Reports;
