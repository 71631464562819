
import LI from "./LI";
import ReactDOM from "react-dom";
import App from "../../App";
import { useHistory } from "react-router-dom";


const MainNavigation = () => {
    let history = useHistory();

  function logout() {
    localStorage.removeItem("manager_id");
    localStorage.removeItem("manager_email");
    localStorage.removeItem("manager_name");

    ReactDOM.render(<App />, document.getElementById("root"));
  }
    function changePass() {
      history.push({
        pathname: "/pass-change",
      });
    }
  return (
    <div style={{ margin: "10px" }}>
      <div style={{ height: "80px" }}>
        <div className="float-end">
          <span>Welcome </span>
          <span className="text-info text-uppercase">
            {localStorage.getItem("manager_name")}
          </span>{" "}
          <span className="btn btn-danger" onClick={logout}>
            {" "}
            Logout{" "}
          </span>
          <span
            style={{ marginLeft: "10px" }}
            className="btn btn-danger"
            onClick={changePass}
          >
            update password
          </span>
        </div>
      </div>
      <nav
        className="navbar navbar-expand-lg navbar-dark fw-bolder bg-secondary"
        style={{ borderRadius: "30px" }}
      >
        <div className="container-fluid">
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <LI text="View Tickets" to="/" />
              <LI text="Reports" to="/reports" />
            </ul>
          </div>
        </div>
      </nav>
      <div className="col-md-12">
        <div
          className="col-md-6 mx-auto text-center"
          id="alert_placeholder"
        ></div>
      </div>
    </div>
  );
};

export default MainNavigation;
