import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";

const TicketSummary = () => {
  const history = useHistory();
  const [loadedTickets, setloadedTickets] = useState([]);
  let request_id = localStorage.getItem("request_id");

  useEffect(() => {
    const spinner = document.getElementById("spinner");
    spinner.style.display = "block";
    comments()
      .then((data) => {
        setloadedTickets(data);
        spinner.style.display = "none";
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const comments = async () => {
    const response = await fetch(
      "https://www.innovations.co.ke/portalapi/public/api/requestDetails/" +
        request_id
    );
    if (response.status !== 200) {
      throw new Error(
        "There was an error fetching comments :" + response.status
      );
    }
    const data = await response.json();
    return data;
  };

  function popup(e) {
    let modal = document.getElementById("myModal");
    let modalImg = document.getElementById("img01");
    let span = document.getElementById("close");
    let rl = e.target.getAttribute("src");
    modalImg.src = rl;

    modal.style.display = "block";

    span.onclick = function () {
      modal.style.display = "none";
    };
  }
  return (
    <div style={{ marginTop: "2%", marginBottom: "5%" }}>
      {loadedTickets.map((dt) => {
        return (
          <div>
            <div className="alert alert-success h2 text-center fw-bolder">
              <span
                className="text-info h1"
                role="button"
                style={{ float: "left" }}
                onClick={() => history.goBack()}
              >
                <i class="fas fa-arrow-left"></i>
              </span>
              Ticket Summary
            </div>
            <table className="table">
              <tbody>
                <tr>
                  <td className="text-warning h5">Subject</td>
                  <td className="text-info fw-bolder">{dt.subject}</td>
                </tr>
                <tr>
                  <td className="text-warning h5">Ticket Description</td>
                  <td className="text-info fw-bolder">
                    {dt.request_description}
                  </td>
                </tr>
                <tr>
                  <td className="text-warning h5">
                    <img
                      src={process.env.PUBLIC_URL + "/images/doc_folder.png"}
                      wiloadedTicketsh="200px"
                      height="200px"
                    />
                  </td>
                  <td>
                    <div>
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflowX: "scroll",
                          width: "800px",
                        }}
                      >
                        {dt.image_url.length != 0
                          ? dt.image_url.map((imgurl) => {
                              return (
                                <img
                                  className="p-3 bg-light border border-success rounded myImg"
                                  onClick={popup}
                                  src={imgurl}
                                  width="200px"
                                  height="200px"
                                  style={{ margin: "5px" }}
                                />
                              );
                            })
                          : ""}
                        {dt.file_url.length != 0
                          ? dt.file_url.map((fileUrl) => {
                              return (
                                <a href={fileUrl} style={{ margin: "10px" }}>
                                  <button className="btn btn-success">
                                    {fileUrl.substring(
                                      fileUrl.lastIndexOf("/") + 1
                                    )}
                                  </button>
                                </a>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-warning h5">Priority</td>

                  <td className="text-info fw-bolder">{dt.priority}</td>
                </tr>
                <tr>
                  <td className="text-warning h5">Ticket Creation Date</td>

                  <td className="text-info fw-bolder">{dt.request_date}</td>
                </tr>
                <tr>
                  <td className="text-warning h5">Expected Completion Date</td>

                  <td className="text-info fw-bolder">
                    {dt.expected_completion_date}
                  </td>
                </tr>

                <tr>
                  <td className="text-warning h5">Request Progress</td>

                  <td className="text-info fw-bolder">{dt.request_progress}</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      })}
      <div id="myModal" class="modal" tabindex="-1">
        <div class="modal-dialog modal-fullscreen modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                id="close"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <img id="img01" width="100%" height="100%" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="spinner-border text-info text-center mx-auto"
        role="status"
        id="spinner"
        style={{
          width: "3rem",
          height: "3rem",
          display: "none",
          marginTop: "20%",
        }}
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default TicketSummary;
