import { showalert } from "../components/Helpers/Utils";
import { useEffect } from "react";

const PassChange = () => {
  useEffect(() => {
    const updatePassFrm = document.getElementById("updatePassFrm");
    updatePassFrm.addEventListener("submit", (e) => {
      const formData = new FormData(updatePassFrm);
      formData.append("manager_id", localStorage.getItem("manager_id"));
      upPass(formData)
        .then((data) => {
            // showalert(data, "success");
          console.log(data)
        })
        .catch((error) => {
          console.log(error);
        });
      e.preventDefault();
    });
  }, []);

  const upPass = async (formDt) => {
    const url = "https://www.innovations.co.ke/portalapi/public/api/updatePassManager";
    const options = {
      method: "POST",
      body: formDt,
    };
    const response = await fetch(url, options);
    if (response.status !== 200) {
      throw new Error(
        "There was an error Updating password:" + response.status
      );
    }
    const data = await response.json();
    return data;
  };

  return (
    <div className="card col-md-4 mx-auto" style={{ marginTop: "5%" }}>
      <div className="card-header text-center bg-white">
        <h1>
          <span className="text-danger fw-bolder">Update Password </span>
        </h1>
      </div>
      <div className="card-body">
        <form id="updatePassFrm" className="d-grid gap-3">
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control"
              autoComplete="off"
              id="password"
              name="password"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Confirm Password</label>
            <input
              type="password"
              className="form-control"
              autoComplete="off"
              id="passwordConfirm"
              name="passwordConfirm"
              required
            />
          </div>
          <button
            type="submit"
            id="submit"
            className="btn btn-secondary mx-auto fs-5"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default PassChange;
